import useWindowWidth from "../../hooks/useWindowWidth";

const Project = (props) => {
	const windowWidth = useWindowWidth();

	return (
		<div className="flex flex-col w-full gap-5">
			<h2 className="text-[22px] font-bold">{props.name}</h2>
			<swiper-container
				space-between="0"
				navigation={`${windowWidth >= 960 ? "true" : "false"}`}
				pagination={`${windowWidth >= 960 ? "false" : "true"}`}
				slides-per-view={`${windowWidth >= 960 ? "3" : "1"}`}
			>
				{props.images.map((image, index) => {
					return (
						<swiper-slide key={index}>
							<img
								className="w-[325px] h-[325px]"
								src={`/img/projects/${image}`}
								alt={`${props.name}`}
							/>
						</swiper-slide>
					);
				})}
			</swiper-container>
		</div>
	);
};

export default Project;
