const Hero = () => {
	return (
		<div
			id="hero"
			className="flex flex-col items-center w-full text-white bg-cover hero-bg"
		>
			<div className="flex flex-col items-center w-4/5 pb-36 text-center pt-[185px]">
				<p className="text-3xl lg:text-3xl text-brand-red">Welcome to</p>
				<h1 className="text-4xl font-bold text-black lg:text-[64px] lg:leading-[78px] lg:pb-[38px]">
					Abinne Projects
					<br />
					International Limited
				</h1>
				<p className="lg:w-[850px] text-lg font-medium lg:text-base overflow-wrap-normal text-brand-gray">
					Abinne Projects International Limited is a dynamic Company with Staff
					who had over 35 years experience in{" "}
					<span className="font-bold">
						Security, Intelligence, Operations, Investigations, Election
						matters, Mediation, Oil and Gas affairs, Special Services, Technical
						matters, Buildings, Security education, Training
					</span>{" "}
					among others. The company is dedicated to serving our clients
					confidentially in areas of needs.
				</p>
			</div>
		</div>
	);
};

export default Hero;
