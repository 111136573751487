import SectionHeader from "../utilComponents/SectionHeader";
import TeamMember from "./TeamMember";
import { team } from "../../info";

const Team = () => {
	return (
		<div id="team" className="text-black section">
			<SectionHeader heading="The Team" />
			<div className="flex flex-col w-11/12 lg:w-5/6 gap-[72px] lg:gap-3 lg:flex-row">
				<div className="w-full lg:w-2/5">
					<h2 className="text-[22px] font-bold">Meet Our Experienced Team</h2>
					<p className="w-full text-sm">
						We're a dynamic group of individuals who are passionate about what
						we do and dedicated to delivering the best results for our clients.
					</p>
				</div>
				<div className="w-full lg:w-3/5">
					<swiper-container
						space-between="6"
						pagination="true"
						loop="true"
						slides-per-view="1"
					>
						{team.map((teamMember, index) => {
							return (
								<swiper-slide key={index}>
									<TeamMember
										image={teamMember.image}
										name={teamMember.name}
										position={teamMember.position}
										bio={teamMember.bio}
									/>
								</swiper-slide>
							);
						})}
						...
					</swiper-container>
				</div>
			</div>
		</div>
	);
};

export default Team;
