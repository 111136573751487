import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import useScrollPosition from "../hooks/useScrollPosition";
import {
	Collapse,
	Typography,
	Button,
	IconButton,
} from "@material-tailwind/react";

export function Nav() {
	const scrollPosition = useScrollPosition();
	const [openNav, setOpenNav] = useState(false);

	useEffect(() => {
		window.addEventListener(
			"resize",
			() => window.innerWidth >= 960 && setOpenNav(false)
		);
	}, []);

	const navArray = ["services", "about", "team", "projects", "FAQ"];

	const navList = (
		<ul className="flex flex-col gap-5 mt-10 mb-4 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
			{navArray.map((navItem, index) => {
				return (
					<Typography
						key={index}
						as="li"
						variant="small"
						color="blue-gray"
						className="p-1 "
					>
						<Link
							onClick={() => setOpenNav(false)}
							to={navItem}
							smooth={true}
							className="flex items-center text-xl capitalize font-semibold hover:text-[#3e1f7d] hover:cursor-pointer text-brand-blue"
						>
							{navItem}
						</Link>
					</Typography>
				);
			})}
		</ul>
	);

	return (
		<div
			className={`${
				scrollPosition > 100 || openNav
					? "bg-gray-500 bg-opacity-60 backdrop-blur-md"
					: ""
			} nav fixed top-0 z-50 w-full px-4 py-2 rounded-none h-max lg:px-20 `}
		>
			<div className="flex items-center justify-between text-blue-gray-900">
				<Typography as="div" className="mr-4 cursor-pointer py-1.5 font-medium">
					<Link to="hero" smooth="true">
						<img
							className="w-[111px]"
							src="/img/logo/nav-logo.svg"
							alt="Company logo"
						/>
					</Link>
				</Typography>
				<div className="flex items-center gap-4">
					<div className="hidden mr-4 lg:block">{navList}</div>
					<Link to="footer" smooth="true">
						<Button
							size="sm"
							className="hidden bg-transparent border-2 lg:inline-block border-brand-blue"
						>
							<span className="text-lg text-brand-blue">Contact Us</span>
						</Button>
					</Link>
					<IconButton
						variant="text"
						className="w-6 h-6 ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
						ripple={false}
						onClick={() => setOpenNav(!openNav)}
					>
						{openNav ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								className="w-6 h-6"
								viewBox="0 0 24 24"
								stroke="#3F3F95"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6"
								fill="none"
								stroke="#3F3F95"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M4 6h16M4 12h16M4 18h16"
								/>
							</svg>
						)}
					</IconButton>
				</div>
			</div>
			<Collapse open={openNav}>
				{navList}
				<Link
					onClick={() => setOpenNav(false)}
					to="footer"
					smooth={true}
					offset={-64}
				>
					<Button
						size="sm"
						fullWidth
						className="mb-2 bg-transparent border-2 border-brand-blue shadow-gray-400 hover:shadow-gray-500"
					>
						<span className="text-2xl text-brand-blue">Contact Us</span>
					</Button>
				</Link>
			</Collapse>
		</div>
	);
}

export default Nav;
