import "./App.css";
import Nav from "./components/Nav";
import Hero from "./components/Hero";
import Services from "./components/services/Services";
import About from "./components/about/About";
import FAQ from "./components/faq/FAQ";
import Team from "./components/team/Team";
import Footer from "./components/Footer";
import Projects from "./components/projects/Projects";
import ScrollToTop from "./components/utilComponents/ScrollToTop";
// swiper
import { register } from "swiper/element/bundle";
register();

function App() {
	return (
		<div className="App">
			<Nav />
			<Hero />
			<Services />
			<About />
			<Team />
			<Projects />
			<FAQ />
			<Footer />
			<ScrollToTop />
		</div>
	);
}

export default App;
