const SectionHeader = (props) => {
	return (
		// using props.textColor as a hack to have a different color in certain sections. styled components is overkill
		<div
			className={`flex flex-col text-center items-center gap-[1px] mb-14 ${
				props.textColor ? `text-${props.textColor}` : "text-brand-blue"
			}`}
		>
			<h2 className="text-2xl lg:text-[45px] capitalize font-bold">
				{props.heading}
			</h2>
			{props.description && (
				<p className="text-xs lg:text-base">{props.description}</p>
			)}
		</div>
	);
};

export default SectionHeader;
