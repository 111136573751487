const Service = (props) => {
	return (
		<div className="flex flex-col gap-[10px] w-11/12 lg:gap-4 rounded-lg ">
			<div className="flex flex-row items-center gap-3 lg:items-start lg:self-start lg:flex-col lg:gap-4">
				<img
					className="w-14"
					src={`/img/services/${props.image}`}
					alt={`${props.name} icon`}
				/>
				<h3 className="font-semibold lg:text-2xl">{props.name}</h3>
			</div>
			<ul className="pl-6 lg:pl-0">
				{props.outlines.map((outline, index) => {
					return (
						<li className="flex flex-row space-x-2" key={index}>
							<img
								className="w-2"
								src="img/services/tick-icon.svg"
								alt="Tick icon"
							/>
							<p className="text-base text-service-gray">{outline}</p>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Service;
