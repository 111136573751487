const AboutContent = (props) => {
	return (
		<div className="flex flex-col items-center gap-1">
			<img
				className="w-[60px]"
				src={`/img/about/${props.image}`}
				alt={`${props.heading} icon`}
			/>
			<h2 className="text-lg font-bold ">{props.heading}</h2>
			<p className="text-[13px] whitespace-pre-line ">{props.content}</p>
		</div>
	);
};

export default AboutContent;
