import SectionHeader from "../utilComponents/SectionHeader";
import Service from "./Service";
import { services as serviceItems } from "../../info.js";

const Services = () => {
	return (
		<div id="services" className="section">
			<SectionHeader
				heading="services we provide"
				description="At APIL, Our services are numerous"
			/>
			<div className="flex flex-col w-5/6 gap-[40px] lg:justify-between lg:flex-row lg:items-start items-center">
				{serviceItems.map((service, index) => {
					return (
						<Service
							key={index}
							image={service.image}
							name={service.name}
							outlines={service.outlines}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default Services;
