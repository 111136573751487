import SectionHeader from "../utilComponents/SectionHeader";
import AboutContent from "./AboutContent";
import {
	vision,
	missionStatement,
	foundersPromise,
	coreValues,
} from "../../info";
import useWindowWidth from "../../hooks/useWindowWidth";

const About = () => {
	const windowWidth = useWindowWidth();

	return (
		<div id="about" className="text-white section bg-brand-blue">
			<SectionHeader textColor="white" heading="about us" />
			<div className="flex flex-col items-center w-11/12 text-center lg:gap-8 lg:flex-row">
				<div className="flex flex-col items-center w-3/5 gap-11 lg:grid lg:grid-cols-2 lg:gap-y-[74px] lg:gap-x-11">
					<AboutContent
						image="vision-icon.svg"
						heading="Our Vision"
						content={vision}
					/>
					<AboutContent
						image="mission-icon.svg"
						heading="Mission Statement"
						content={missionStatement}
					/>
					<div className="flex flex-col items-center gap-1">
						<img
							className="w-[60px]"
							src="/img/about/values-icon.svg"
							alt="Values icon"
						/>
						<div>
							<h2 className="text-xl font-bold">
								Core Values{" "}
								<span className="text-sm">{`(${coreValues.acronym})`}</span>
							</h2>
						</div>
						<ul className="lg:grid lg:grid-cols-2 lg:gap-x-16">
							{coreValues.values.map((value, index) => {
								return (
									<li key={index} className="text-[13px]">{`- ${value}`}</li>
								);
							})}
						</ul>
					</div>
					<AboutContent
						image="promise-icon.svg"
						heading="Founder's Promise"
						content={foundersPromise}
					/>
				</div>
				{windowWidth >= 960 && (
					<img
						className="lg:w-[588px]"
						src="/img/about/guard.svg"
						alt="A security guard"
					/>
				)}
			</div>
		</div>
	);
};

export default About;
