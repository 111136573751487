import SectionHeader from "../utilComponents/SectionHeader";
import FAQAccordion from "./FAQAccordion";

const FAQ = () => {
	return (
		<div id="FAQ" className="section">
			<SectionHeader heading="Frequently Asked Questions" textColor="black" />
			<FAQAccordion />
		</div>
	);
};

export default FAQ;
