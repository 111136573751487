import { Link } from "react-scroll";
import useScrollPosition from "../../hooks/useScrollPosition";

const ScrollToTop = () => {
	const scrollPosition = useScrollPosition();
	return (
		<Link to="hero" smooth="true">
			<button
				className={`${
					scrollPosition > 450
						? "opacity-100 bottom-6 right-6"
						: "opacity-0 -bottom-16"
				} fixed z-20 p-2 border-2 rounded-full shadow-md bg-opacity-60 backdrop-blur-sm bg-blue-gray-500  border-brand-blue transition ease-in-out duration-1000`}
				aria-label="Scroll to Top"
			>
				<img
					className="h-8"
					src="/img/to-top-icon.svg"
					alt="Arrow pointing up"
				/>
			</button>
		</Link>
	);
};

export default ScrollToTop;
