const TeamMember = (props) => {
	return (
		<div className="flex flex-col gap-[20px]">
			<div className="flex flex-row gap-[14px] items-center">
				<img
					className="rounded-lg w-[180px]"
					src={`/img/the-team/${props.image}`}
					alt={props.name}
				/>
				<div className="flex flex-col gap-[5px] ">
					<h2 className="text-2xl font-semibold">{props.name}</h2>
					<h4 className="text-base text-service-gray">{props.position}</h4>
				</div>
			</div>

			<p className="text-lg font-medium text-[#4A4E58] max-h-32 overflow-auto">
				{props.bio}
			</p>
		</div>
	);
};

export default TeamMember;
