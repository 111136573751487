import { useState } from "react";
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";
import { faq as faqItems } from "../../info";

const FAQAccordion = () => {
	const [open, setOpen] = useState(0);

	const handleOpen = (value) => setOpen(open === value ? 0 : value);

	function Icon({ id, open }) {
		return open === id ? (
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="PlusCircle">
					<path
						id="Vector"
						d="M10.3125 15H19.6875"
						stroke="black"
						strokeWidth="1.3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</svg>
		) : (
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="PlusCircle">
					<path
						id="Vector"
						d="M10.3125 15H19.6875"
						stroke="black"
						strokeWidth="1.3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Vector_2"
						d="M15 10V19.375"
						stroke="black"
						strokeWidth="1.3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</svg>
		);
	}

	return (
		<div className="w-4/5 whitespace-pre-line">
			{faqItems.map((item) => {
				return (
					<Accordion
						key={item.id}
						open={open === item.id}
						icon={<Icon id={item.id} open={open} />}
					>
						<AccordionHeader onClick={() => handleOpen(item.id)}>
							<p
								className={`${
									open === item.id ? "text-brand-blue" : "text-black"
								} font-brand-font text-xl`}
							>
								{item.question}
							</p>
						</AccordionHeader>
						<AccordionBody>
							<ul className="list-disc list-inside flex flex-col gap-[10px]">
								{item.answers.map((answer, index) => {
									return (
										<li key={index} className="text-base text-faq-gray">
											{answer}
										</li>
									);
								})}
							</ul>
						</AccordionBody>
					</Accordion>
				);
			})}
		</div>
	);
};
// &#10686;
export default FAQAccordion;
