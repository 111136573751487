import SectionHeader from "../utilComponents/SectionHeader";
import Project from "./Project";
import { projects } from "../../info";

const Projects = () => {
	return (
		<div id="projects" className="section">
			<SectionHeader heading="Projects" />
			<div className="w-5/6 flex flex-col gap-[60px]">
				{projects.map((project, index) => {
					return (
						<Project key={index} name={project.name} images={project.images} />
					);
				})}
			</div>
		</div>
	);
};

export default Projects;
